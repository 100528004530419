import { dispatchCustomEvent } from "./customEvent"
import { CUSTOM_EVENT_TOGGLE_THEME } from "@pitayan/gatsby-theme-pitayan/src/constants"

export const checkIfDarkMode = (): boolean => {
  // Check if localStorage is available
  if (typeof window !== "undefined" && window.localStorage) {
    // Check if localStorage.theme is set to "dark"
    if (localStorage.theme === "dark") {
      return true
    }
    // Check if localStorage.theme is set to "light"
    if (localStorage.theme === "light") {
      return false
    }
    // Check if prefers-color-scheme is dark, return true
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      return true
    }
  }
  // Fallback: return true if localStorage is not available or if window is not defined
  return true
}

export const toggleDark = (isDark: boolean): void => {
  if (typeof window !== "undefined" && window.localStorage) {
    if (isDark) {
      document.documentElement.classList.add("dark")
    } else {
      document.documentElement.classList.remove("dark")
    }

    // Whenever the user explicitly chooses light mode
    localStorage.theme = isDark ? "dark" : "light"

    // Dispatch Custom event
    dispatchCustomEvent(CUSTOM_EVENT_TOGGLE_THEME, {
      theme: localStorage.theme,
    })
  }
}
