exports.components = {
  "component---gatsby-theme-pitayan-src-pages-404-index-tsx": () => import("./../../../../gatsby-theme-pitayan/src/pages/404/index.tsx" /* webpackChunkName: "component---gatsby-theme-pitayan-src-pages-404-index-tsx" */),
  "component---gatsby-theme-pitayan-src-pages-authors-index-tsx": () => import("./../../../../gatsby-theme-pitayan/src/pages/authors/index.tsx" /* webpackChunkName: "component---gatsby-theme-pitayan-src-pages-authors-index-tsx" */),
  "component---gatsby-theme-pitayan-src-pages-categories-index-tsx": () => import("./../../../../gatsby-theme-pitayan/src/pages/categories/index.tsx" /* webpackChunkName: "component---gatsby-theme-pitayan-src-pages-categories-index-tsx" */),
  "component---gatsby-theme-pitayan-src-pages-donate-tsx": () => import("./../../../../gatsby-theme-pitayan/src/pages/donate.tsx" /* webpackChunkName: "component---gatsby-theme-pitayan-src-pages-donate-tsx" */),
  "component---gatsby-theme-pitayan-src-pages-index-tsx": () => import("./../../../../gatsby-theme-pitayan/src/pages/index.tsx" /* webpackChunkName: "component---gatsby-theme-pitayan-src-pages-index-tsx" */),
  "component---gatsby-theme-pitayan-src-templates-all-posts-index-tsx": () => import("./../../../../gatsby-theme-pitayan/src/templates/allPosts/index.tsx" /* webpackChunkName: "component---gatsby-theme-pitayan-src-templates-all-posts-index-tsx" */),
  "component---gatsby-theme-pitayan-src-templates-author-posts-index-tsx": () => import("./../../../../gatsby-theme-pitayan/src/templates/authorPosts/index.tsx" /* webpackChunkName: "component---gatsby-theme-pitayan-src-templates-author-posts-index-tsx" */),
  "component---gatsby-theme-pitayan-src-templates-category-posts-index-tsx": () => import("./../../../../gatsby-theme-pitayan/src/templates/categoryPosts/index.tsx" /* webpackChunkName: "component---gatsby-theme-pitayan-src-templates-category-posts-index-tsx" */),
  "component---gatsby-theme-pitayan-src-templates-post-index-tsx-content-file-path-content-posts-2024-02-19-advocacy-for-palestinians-social-media-accounts-index-mdx": () => import("./../../../../gatsby-theme-pitayan/src/templates/post/index.tsx?__contentFilePath=/opt/build/repo/packages/www/content/posts/2024-02-19-advocacy-for-palestinians-social-media-accounts/index.mdx" /* webpackChunkName: "component---gatsby-theme-pitayan-src-templates-post-index-tsx-content-file-path-content-posts-2024-02-19-advocacy-for-palestinians-social-media-accounts-index-mdx" */),
  "component---gatsby-theme-pitayan-src-templates-post-index-tsx-content-file-path-content-posts-2024-02-19-tech-for-palestine-index-mdx": () => import("./../../../../gatsby-theme-pitayan/src/templates/post/index.tsx?__contentFilePath=/opt/build/repo/packages/www/content/posts/2024-02-19-tech-for-palestine/index.mdx" /* webpackChunkName: "component---gatsby-theme-pitayan-src-templates-post-index-tsx-content-file-path-content-posts-2024-02-19-tech-for-palestine-index-mdx" */),
  "component---gatsby-theme-pitayan-src-templates-site-index-tsx-content-file-path-content-site-privacy-policy-index-md": () => import("./../../../../gatsby-theme-pitayan/src/templates/site/index.tsx?__contentFilePath=/opt/build/repo/packages/www/content/site/privacy-policy/index.md" /* webpackChunkName: "component---gatsby-theme-pitayan-src-templates-site-index-tsx-content-file-path-content-site-privacy-policy-index-md" */),
  "component---gatsby-theme-pitayan-src-templates-site-index-tsx-content-file-path-content-site-submit-an-article-index-md": () => import("./../../../../gatsby-theme-pitayan/src/templates/site/index.tsx?__contentFilePath=/opt/build/repo/packages/www/content/site/submit-an-article/index.md" /* webpackChunkName: "component---gatsby-theme-pitayan-src-templates-site-index-tsx-content-file-path-content-site-submit-an-article-index-md" */),
  "component---gatsby-theme-pitayan-src-templates-site-index-tsx-content-file-path-content-site-terms-of-service-index-md": () => import("./../../../../gatsby-theme-pitayan/src/templates/site/index.tsx?__contentFilePath=/opt/build/repo/packages/www/content/site/terms-of-service/index.md" /* webpackChunkName: "component---gatsby-theme-pitayan-src-templates-site-index-tsx-content-file-path-content-site-terms-of-service-index-md" */)
}

